<template>
  <div class="my-1">
    <b-row
      v-for="(rows, index) of seatMap"
      :key="`row-wrap-${index}`"
      :class="`flex-nowrap
      ${index === 0 ? 'mb-3' :''}
      `"
    >
      <div
        v-for="(colSeats, indexColSeat) of rows"
        :key="`row-seat-${indexColSeat}`"
        :class="`flex-nowrap px-2 ${colSeats === 'block' ? 'd-flex-center' : ''}`"
      >
        <template v-if="colSeats !== 'block'">
          <div
            v-for="(cols, indexCols) of colSeats"
            :key="`cols-seat-${indexCols}`"
            class="d-flex"
          >
            <div
              v-for="seat of cols"
              :key="seat"
            >
              <TicketSeatButton
                :seat="resolveDataSeat(availSeats, seat, itineraryIndex)"
                :isRight="isRightSeat(seat)"
              />
            </div>
          </div>
        </template>

        <div
          v-else
          class="bg-secondary d-flex-center"
          style="width: 38px; height: 50px"
        />
      </div>
    </b-row>
  </div>
</template>

<!-- SEAT -->
<script>
// import { ref, toRefs } from '@vue/composition-api'
import { BRow } from 'bootstrap-vue'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    BRow,
    // BCol,
    TicketSeatButton: () => import('./TicketSeatButton.vue'),
  },
  props: {
    availSeats: {
      type: Array,
      default: () => [],
    },
    itineraryIndex: {
      type: Number,
      default: () => 0,
    },
  },
  setup() {
    const {
      resolveDataSeat,
    } = useTrainHandle()

    function isRightSeat(index) {
      return index > 32
    }

    const seatMap = [
      [
        [
          [1, 8, 9, 16, 17, 24, 25, 32],
          [2, 7, 10, 15, 18, 23, 26, 31],
        ],
        'block',
        [
          [33, 40, 41, 48, 49, 56, 57, 64],
          [34, 39, 42, 47, 50, 55, 58, 63],
        ],
      ],
      [
        [
          [3, 6, 11, 14, 19, 22, 27, 30],
          [4, 5, 12, 13, 20, 21, 28, 29],
        ],
        'block',
        [
          [35, 38, 43, 46, 51, 54, 59, 62],
          [36, 37, 44, 45, 52, 53, 60, 61],
        ],
      ],
    ]

    return {
      seatMap,
      isRightSeat,
      resolveDataSeat,
    }
  },
}
</script>

<style lang="">

</style>
